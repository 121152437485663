<script>
import StoreStatistics from '@app/components/StoreStatistics.vue';

export default {
  name: 'Customers',
  components: { StoreStatistics },
  head: {
    title: 'Statistiques',
  },
  data: () => ({ isExporting: false }),
  methods: {
    exportCSV() {
    },
  },
};
</script>

<template>
  <div class="container is-728">
    <div class="level">
      <div class="level-left">
        <h1 class="title">
          Statistiques récentes
        </h1>
      </div>
      <div class="level-right">
        <!-- <b-tooltip label="Compatible Excel &amp; Google Sheet" position="is-left">
          <b-button
            type="is-primary"
            icon-left="file-export"
            @click="exportCSV"
            :loading="isExporting">
            Export CSV
          </b-button>
        </b-tooltip> -->
      </div>
    </div>

    <StoreStatistics />
  </div>
</template>
