<script>
import pickBy from 'lodash/pickBy';
import theme from '@shared/assets/styles/theme';
import utilsMixin from '@shared/mixins/utils';
import APIStore from '@app/services/API/Store';
import BaseChartJS from '@app/components/BaseChartJS.vue';
import MetricsWrapper from '@app/components/MetricsWrapper.vue';
import StatisticCard from '@shared/components/StatisticCard.vue';

export default {
  name: 'StoreStatistics',
  mixins: [utilsMixin],
  components: { MetricsWrapper, StatisticCard, BaseChartJS },
  data() {
    return {
      isFetching: true,
      stats: {
        range: [null, null],
        data: null,
        content: {
          nb_customer: {
            title: 'Apprenants',
            text: 'Nombre de personnes inscrites à vos formations',
            to: { name: 'customers' },
          },
          nb_sales: {
            title: 'Ventes',
            text: 'Nombre de formations payées par des apprenants',
            to: { name: 'sales' },
          },
          gain: {
            title: 'Revenus',
            text: 'Sommes des ventes que vous avez effectués',
            to: { name: 'sales' },
            format: (val) => `${utilsMixin.filters.formatPrice(val)}`,
          },
          nb_abandoned_cart: {
            title: 'Abandonnés',
            text: 'Nombre de personnes n\'ayant pas finalisé un achat',
            to: { name: 'customers', query: { type: 'ABANDONED_CART' } },
          },
          last_sale_at: {
            title: 'Dernière vente effectuée : ',
            text: 'Dernière formation qu\'un apprenant a acheté',
            format: (val) => {
              if (!val) {
                return 'N/A';
              }

              return `Le ${this.$moment.utc(val).local().format('DD/MM/YYYY')}`;
            },
          },
          nb_customer_completed: {
            title: 'Nombre d\'apprenants ayant fini une formation : ',
            text: 'Voici le nombre de personnes ayant fini au moins une formation',
            format: (val, all) => {
              if (!all.nb_customer) {
                return 'N/A';
              }

              const percent = `(${Math.round((val / all.nb_customer) * 100)}%)`;
              return `${val} / ${all.nb_customer} ${all.nb_customer < 1 ? '' : percent}`;
            },
          },
          nb_customer_started: {
            title: 'Nombre d\'apprenants ayant commencé une formation : ',
            text: 'Voici le nombre de personnes ayant commencé au moins une formation',
            format: (val, all) => {
              if (!all.nb_customer) {
                return 'N/A';
              }

              const percent = `(${Math.round((val / all.nb_customer) * 100)}%)`;
              return `${val} / ${all.nb_customer} ${val < 1 ? '' : percent}`;
            },
          },
        },
      },
      statsHistogram: {},
    };
  },
  computed: {
    store() {
      return this.$store.getters['auth/store'];
    },
    mainStatsContent() {
      return this.stats.data && pickBy(this.stats.content, (v, k) => [
        'nb_customer', 'nb_sales', 'gain', 'nb_abandoned_cart',
      ].includes(k));
    },
    otherStatsContent() {
      return this.stats.data && pickBy(this.stats.content, (v, k) => [
        'last_sale_at', 'nb_customer_completed', 'nb_customer_started',
      ].includes(k));
    },
    chartData() {
      const data = this.statsHistogram;
      const color = theme.color.primary;

      return {
        customer: {
          labels: Object.keys(data.customer).map((v) => this.$moment(v).format('DD/MM/YY')),
          datasets: [{
            label: 'Apprenants ayant rejoint cette formation',
            data: Object.values(data.customer),
            fill: 'origin',
            backgroundColor: color,
            pointStyle: 'line',
          }],
        },
        revenue: {
          labels: Object.keys(data.revenue).map((v) => this.$moment(v).format('DD/MM/YY')),
          datasets: [{
            label: 'Revenus de cette formation',
            data: Object.values(data.revenue),
            fill: 'origin',
            backgroundColor: color,
            pointStyle: 'line',
          }],
        },
        sale: {
          labels: Object.keys(data.sale).map((v) => this.$moment(v).format('DD/MM/YY')),
          datasets: [{
            label: 'Nombre de ventes sur cette formation',
            data: Object.values(data.sale),
            fill: 'origin',
            backgroundColor: color,
            pointStyle: 'line',
          }],
        },
      };
    },
    chartOptions() {
      return {
        customer: {
          scales: {
            y: {
              beginAtZero: true,
              grace: '5%',
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
        revenue: {
          scales: {
            y: {
              beginAtZero: true,
              grace: '5%',
              ticks: {
                stepSize: 10,
              },
            },
          },
        },
        sale: {
          scales: {
            y: {
              beginAtZero: true,
              grace: '5%',
              ticks: {
                stepSize: 1,
              },
            },
          },
        },
      };
    },
    valueSum() {
      return {
        customer: Object.values(this.statsHistogram.customer)
          .reduce((prev, curr) => (prev + curr), 0),
        revenue: Object.values(this.statsHistogram.revenue)
          .reduce((prev, curr) => (prev + curr), 0),
        sale: Object.values(this.statsHistogram.sale)
          .reduce((prev, curr) => (prev + curr), 0),
      };
    },
  },
  watch: {
    'stats.range': {
      handler() {
        let [begin, end] = this.stats.range;
        const tz = this.$moment().format('Z');

        begin = this.$moment(begin).format('YYYY-MM-DD');
        end = this.$moment(end).format('YYYY-MM-DD');

        let histgramApiCall = Promise.resolve();

        if (this.$store.getters['auth/hasExpertPlan']) {
          const beginGraph = this.$moment(begin)
            .startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
          const endGraph = this.$moment(end)
            .endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');

          histgramApiCall = APIStore.getHistogramStats(this.store.uuid, {
            tz, begin: beginGraph, end: endGraph,
          });
        }

        this.isFetching = true;
        Promise.all([
          APIStore.getStats(this.store.uuid, { begin, end }),
          histgramApiCall,
        ])
          .then(([stats, statsHistogram]) => this.setStats(stats, statsHistogram))
          .finally(() => (this.isFetching = false));
      },
    },
  },
  methods: {
    setStats(stats, statsHistogram) {
      stats = stats.data;

      Object.keys(stats.current).forEach((k) => {
        const stat = this.stats.content[k];

        if (!stat || !('format' in stat)) {
          return;
        }

        stats.current[k] = stat.format(stats.current[k], stats.current);
        stats.previous[k] = stat.format(stats.previous[k], stats.previous);
      });

      this.stats.data = stats;
      this.statsHistogram = (statsHistogram && statsHistogram.data) || {};
    },
  },
};
</script>

<template>
  <MetricsWrapper v-model="stats.range" initialSelector="LAST_30_DAYS" :isLoading="isFetching">
    <b-skeleton v-if="isFetching" height="500" />
    <template v-else>
      <div v-if="stats.data" class="columns is-multiline">
        <div v-for="(stat, k) in mainStatsContent" :key="k" class="column is-6">
          <router-link class="cursor-pointer" tag="div" :to="stat.to" :title="stat.text">
            <StatisticCard class="h-full">
              <template #title>
                {{ stat.title }}
              </template>
              <template #default>
                {{ stats.data.current[k] }}
              </template>
              <template #footer>
                <p class="level is-mobile w-full is-size-8">
                  <span class="level-left">
                    Période précédente :
                  </span>
                  <strong class="level-right">
                    {{ stats.data.previous[k] }}
                  </strong>
                </p>
              </template>
            </StatisticCard>
          </router-link>
        </div>
      </div>

      <ul class="panel mt-3 has-background-white">
        <li v-for="(stat, k) in otherStatsContent" :key="k" class="panel-block">
          <div class="level is-mobile w-full is-size-8" :title="stat.text">
            <h3 class="level-left w-full is-flex-shrink-1 is-size-7">
              {{ stat.title }}
            </h3>
            <p class="level-right is-size-6 has-text-weight-bold ml-3 is-align-self-flex-end">
              {{ stats.data.current[k] }}
            </p>
          </div>
        </li>
      </ul>

      <h2 class="title is-4">
        Graphiques
        <AppFeatureBadge class="ml-3 is-size-5" position="is-right" planLock="EXPERT" />
      </h2>

      <GoProWrapper
        planLock="EXPERT"
        :hasSep="false"
        height="260px"
        :bg="require('@app/assets/img/chart_example.png')"
      >
        <template v-if="$store.getters['auth/hasExpertPlan']">
          <div class="box">
            <h3 class="title is-6">
              Apprenants
            </h3>
            <BaseChartJS
              type="line"
              :data="chartData.customer"
              :options="chartOptions.customer"
            />
            <p class="is-primary mt-5 has-text-centered">
              {{ valueSum.customer }} apprenants ont rejoint vos formations sur cette période.
            </p>
          </div>
          <div class="box">
            <h3 class="title is-6">
              Ventes
            </h3>
            <BaseChartJS
              type="line"
              :data="chartData.sale"
              :options="chartOptions.sale"
            />
            <p class="is-primary mt-5 has-text-centered">
              Vos formations ont généré {{ valueSum.sale }} ventes sur cette période.
            </p>
          </div>
          <div class="box">
            <h3 class="title is-6">
              Revenus
            </h3>
            <BaseChartJS
              type="line"
              :data="chartData.revenue"
              :options="chartOptions.revenue"
            />
            <p class="is-primary mt-5 has-text-centered">
              Vos formations ont généré {{ valueSum.revenue | formatPrice }} sur cette période.
            </p>
          </div>
        </template>
      </GoProWrapper>
    </template>
  </MetricsWrapper>
</template>
